@import url('tailwind.css');

:root {
  --primary: #3b69d1;
  --primary-darken: #274996;
}

[data-theme='darker'] {
  --primary: #274996;
  --primary-darken: #172b5a;
}

html {
  min-height: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--color-white);
  color: var(--color-black);
  overflow-y: hidden;
}

body {
  height: 100dvh;
  padding: 0;
  margin: 0;
  color: var(--color-content-color);
  position: fixed;
}

@supports not (height: 100dvh) {
  body {
    height: 100vh;
  }
}

textarea {
  resize: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.select::after {
  content: '';
  position: absolute;
  top: 30%;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  transform: translateY(-50%);
}

input:disabled,
select:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

[type='date']:not(:read-only) {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}
